import { Component, EventEmitter, Input, Output, ChangeDetectionStrategy } from '@angular/core';
import { Subject } from 'rxjs';

@Component({
  selector: 'app-keyboard',
  templateUrl: './keyboard.component.html',
  styleUrls: ['./keyboard.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class KeyboardComponent {
  isShiftMode = false;
  isSpecialMode = false;
  @Input() isExpandedMode = false; // New property to manage the mode

  alphabeticRows = [
    [
      { label: 'q', disabled: false },
      { label: 'w', disabled: false },
      { label: 'e', disabled: false },
      { label: 'r', disabled: false },
      { label: 't', disabled: false },
      { label: 'y', disabled: false },
      { label: 'u', disabled: false },
      { label: 'i', disabled: false },
      { label: 'o', disabled: false },
      { label: 'p', disabled: false },
    ],
    [
      { label: 'a', disabled: false },
      { label: 's', disabled: false },
      { label: 'd', disabled: false },
      { label: 'f', disabled: false },
      { label: 'g', disabled: false },
      { label: 'h', disabled: false },
      { label: 'j', disabled: false },
      { label: 'k', disabled: false },
      { label: 'l', disabled: false },
    ],
    [
      { label: '⇧', disabled: false, action: 'shift' },
      { label: 'z', disabled: false },
      { label: 'x', disabled: false },
      { label: 'c', disabled: false },
      { label: 'v', disabled: false },
      { label: 'b', disabled: false },
      { label: 'n', disabled: false },
      { label: 'm', disabled: false },
      { label: '⌫', disabled: false, action: 'backspace' },
    ],
  ];

  expandedAlphabeticRows = [
    ...this.alphabeticRows,
    [
      { label: '?123', disabled: false, action: 'special' },
      { label: ',', disabled: false },
      { label: '\'', disabled: false },
      { label: '␣', disabled: false, action: 'space' },
      { label: '.', disabled: false },
      { label: '→', disabled: false, action: 'enter' },
    ],
  ];

  specialRows = [
    [
      { label: '1', disabled: false },
      { label: '2', disabled: false },
      { label: '3', disabled: false },
      { label: '4', disabled: false },
      { label: '5', disabled: false },
      { label: '6', disabled: false },
      { label: '7', disabled: false },
      { label: '8', disabled: false },
      { label: '9', disabled: false },
      { label: '0', disabled: false },
    ],
    [
      { label: '!', disabled: false },
      { label: '@', disabled: false },
      { label: '\'', disabled: false },
      { label: '$', disabled: false },
      { label: '%', disabled: false },
      { label: '^', disabled: false },
      { label: '&', disabled: false },
      { label: '*', disabled: false },
      { label: '(', disabled: false },
      { label: ')', disabled: false },
    ],
    [
      { label: '¿', disabled: false },
      { label: '¡', disabled: false },
      { label: 'á', disabled: false },
      { label: 'é', disabled: false },
      { label: 'í', disabled: false },
      { label: 'ó', disabled: false },
      { label: 'ú', disabled: false },
      { label: 'ñ', disabled: false },
      { label: '⌫', disabled: false, action: 'backspace' },
    ],
    [
      { label: '?123', disabled: false, action: 'special' },
      { label: ',', disabled: false },
      { label: '\'', disabled: false },
      { label: '␣', disabled: false, action: 'space' },
      { label: '.', disabled: false },
      { label: '→', disabled: false },
    ],
  ];

  get rows() {
    if (this.isSpecialMode) {
      return this.specialRows;
    }
    const alphabeticRows = this.isShiftMode
      ? this.alphabeticRows.map(row => row.map(key => ({ ...key, label: key.label.toUpperCase() })))
      : this.alphabeticRows;
    return this.isExpandedMode ? this.expandedAlphabeticRows : alphabeticRows;
  }

  @Output() keyClick = new EventEmitter<string>();
  @Output() backspaceClick = new EventEmitter<void>();
  @Output() enterClick = new EventEmitter<void>();

  private keyClickSubject = new Subject<{ label: string; disabled: boolean; action?: string }>();

  constructor() {
    this.keyClickSubject.subscribe(key => this.handleKeyClick(key));
  }

  onKeyClick(key: { label: string; disabled: boolean; action?: string }): void {
    this.keyClickSubject.next(key);
  }

  private handleKeyClick(key: { label: string; disabled: boolean; action?: string }): void {
    if (!key.disabled) {
      if (key.action === 'shift') {
        this.toggleShiftMode();
      } else if (key.action === 'special') {
        this.toggleSpecialMode();
      } else if (key.action === 'alphabet') {
        this.toggleSpecialMode();
      } else if (key.action === 'space') {
        this.keyClick.emit(' ');
      } else {
        this.keyClick.emit(key.label);
        if (this.isShiftMode) {
          this.resetShiftMode();
        }
        // Reset special mode if any other key is pressed
        this.isSpecialMode = false;
      }
    }
  }

  toggleShiftMode(): void {
    this.isShiftMode = !this.isShiftMode;
  }

  resetShiftMode(): void {
    this.isShiftMode = false;
  }

  toggleSpecialMode(): void {
    this.isSpecialMode = !this.isSpecialMode;
  }

  toggleExpandedMode(): void {
    this.isExpandedMode = !this.isExpandedMode;
  }

  trackByFn(index: number, item: any): any {
    return item.label; // or any unique identifier
  }
}